import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';
import '../global-sass/badge.scss';

const Badge = ({ count }) => {
	const labels = useLabels();

	return (
		<span className="chip chip-primary chip-small ml-4 mycars-nav-badge-btn">
			{`${count} ${labels.get('NEW')}`}
		</span>
	);
};

Badge.propTypes = {
	count: PropTypes.number.isRequired
};

export default Badge;
